<template>
  <div class="engagement-and-post-entry">
    <Content class="chart" title="Engagement and posts" description="See how your audience interacted with the posts.">
      <canvas ref="chart-el"></canvas>
    </Content>
    <Content class="summaries" title="Engagement Rollup">
      <div class="summary" v-for="summary in totalComputedData" :key="summary.name">
        <div class="title">{{ summary.name }}</div>
        <div class="value">{{ summary.value }}</div>
      </div>
    </Content>
  </div>
</template>

<script>
import { get } from 'lodash';
import Content from '~/components/Reports/partials/Content';
import ChartJs from 'chart.js';

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: {
        instance: null,
      },
    };
  },
  components: {
    Content,
  },
  async mounted() {
    await this.$nextTick();
    this.loadChart();
  },
  beforeDestroy() {
    this.destroyChart();
  },
  computed: {
    getData() {
      return (key, defaultVal) => {
        return get(this, key, defaultVal);
      };
    },
    chartComputedData() {
      return {
        labels: this.getData('chartData.labels', []),
        datasets: [
          {
            label: 'comments',
            data: this.getData('chartData.comments', []),
            fill: 'origin',
            borderColor: 'rgb(255, 122, 0)',
            backgroundColor: 'rgba(255, 122, 0, 0.2)',
            lineTension: 0.4,
          },
          {
            label: 'reposts',
            data: this.getData('chartData.reposts', []),
            fill: 'origin',
            borderColor: 'rgb(147, 162, 245)',
            backgroundColor: 'rgba(147, 162, 245, 0.2)',
            lineTension: 0.4,
          },
          {
            label: 'reactions',
            data: this.getData('chartData.reactions', []),
            fill: 'origin',
            borderColor: 'rgb(104, 219, 107)',
            backgroundColor: 'rgba(104, 219, 107, 0.2)',
            lineTension: 0.4,
          },
          {
            label: 'posts published',
            data: this.getData('chartData.posts', []),
            fill: 'origin',
            borderColor: 'rgb(63, 81, 181)',
            backgroundColor: 'rgba(63, 81, 181, 0.2)',
            lineTension: 0.4,
          },
        ],
      };
    },
    totalComputedData() {
      return [
        {
          name: 'total posts',
          value: this.getData('total.posts', 0),
        },
        {
          name: 'posts per day',
          value: this.getData('total.post_per_day', 0),
        },
        {
          name: 'total engagement',
          value: this.getData('total.engagements', 0),
        },
        {
          name: 'engagements per day',
          value: this.getData('total.engagement_per_day', 0),
        },
      ];
    },
  },
  watch: {
    chartData: {
      handler() {
        this.loadChart();
      },
      deep: true,
    },
  },
  methods: {
    loadChart() {
      this.destroyChart();
      const { 'chart-el': chartCanvasEl } = this.$refs;

      this.chart.instance = new ChartJs(chartCanvasEl, {
        type: 'line',
        data: this.chartComputedData,
        options: {
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  zeroLineColor: 'rgb(63, 81, 181)',
                  zeroLineWidth: 5,
                },
                ticks: {
                  autoSkip: false,
                },
              },
            ],
          },
        },
      });
    },
    destroyChart() {
      if (this.chart.instance) this.chart.instance.destroy();
      this.chart.instance = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.engagement-and-post-entry {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  ::v-deep {
    > .chart {
      width: 63%;
    }

    > .summaries {
      width: 35%;

      > .content {
        height: 70%;
        margin: 30px 0;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 1;

        > .summary {
          width: 49%;
          display: inline-flex;
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          padding: 20px;
          flex-wrap: wrap;
          align-content: space-between;

          &:nth-child(n + 3) {
            margin: 30px 0 0 0;
          }

          > * {
            display: flex;
            width: 100%;
            color: #404040;
            flex-wrap: wrap;
            white-space: normal;
            word-wrap: break-word;
          }

          > .title {
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
          }

          > .value {
            margin: 25px 0 0 0;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
